import React, {useEffect, useState} from "react";
import { Link } from 'react-router-dom';
const Home = () =>{
  const [userdata,setData]=useState ([]);
  
  useEffect(()=>{  
   // console.log("heeloo hhhh");

   fetch("https://howcanfind.com/reactapi/listcoursenew.php").then((result)=>{
    result.json().then((resp)=>{
        // console.warn("result",resp)
         console.log(resp) 
         setData(resp.data)
       })
})

},[]);

   //console.log(userdata);

  return (
    <div className="container">
      <h1>Contact List</h1>

      <table class="table">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">First</th>
      <th scope="col">Last</th>
      <th scope="col">Handle</th>
    </tr>
  </thead>
  <tbody>

  {
        userdata.map((item)=> 
   <tr>
   <th scope="row">{item.id}</th>
   <td>{item.id}</td> 
<td>{item.product_name}</td>
<td>
<Link to="/contact" >view</Link>
<Link to={`/userlist/EditUser/${item.id}`}>Edit</Link>
<form >
<input type="text" class="form-control"
      name = "titlecourse"      
      value={item.id}      
     /></form>
<Link to="/contact">Delete</Link>
</td>
</tr>

        )
      }

   
    
    
  </tbody>
</table>


    </div>
  );
};
export default Home;
