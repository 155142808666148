import React,{useState} from 'react';
//import axios from 'axios';
import { useNavigate } from 'react-router-dom';
 
//import './App.css';
const AddUser=()=>{
//function Home() {  
  
    const history = useNavigate();
    const[titlecourse,settitlecourse]=useState("");
    const[listshow,setlistshow]=useState("");
    //console.log(titlecourse);

    const onSubmit = async (event) => {
        event.preventDefault();
        console.log(titlecourse);
        //const data = this.state.titlecourse;
        //const dataq = 11;

        //https://howcanfind.com/reactapi/addlistcourse.php

        await fetch("https://howcanfind.com/reactapi/addlistcourse.php",{
            method: 'POST',
            headers: new Headers({
                       'Content-Type': 'application/x-www-form-urlencoded',
              }),
              body: new URLSearchParams({
                titlecourse:titlecourse,
                //listshow:'tt'
                listshow:listshow
              })
             // right= body: "titlecourse=sassa&listshow=jhjhg"
           // right= body: "titlecourse= " + data
          // body:{
            //   "titlecourse":this.state.titlecourse             
           //}

      
        }).then((res)=>console.log(res));

        // after submit emtey
        settitlecourse("")
        setlistshow("")
        history('/')

    }



  return (
    <div className="container">
      <h1>Add User</h1>
      <form onSubmit={e=>onSubmit(e)}>
  <div class="row mb-3">
    <label for="inputEmail3" class="col-sm-2 col-form-label">titlecourse</label>
    <div class="col-sm-10">
      <input type="text" class="form-control"
      name = "titlecourse"
      value={titlecourse}
      onChange={(e)=>{settitlecourse(e.target.value)}}
     />
    </div>
  </div>
  <div class="row mb-3">
    <label for="inputPassword3" class="col-sm-2 col-form-label">listshow</label>
    <div class="col-sm-10">
      <input type="text" class="form-control"
      name = "listshow"
      value={listshow}      
      onChange={(e)=>{setlistshow(e.target.value)}}
      />
    </div>
  </div>
  
  
  <button type="submit" class="btn btn-primary">Sign in</button>
</form>

    </div>
  );
}

export default AddUser;
