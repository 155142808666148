import React from 'react';
//import './App.css';
const About=()=>{
//function Home() {
  return (
    <div className="container">
      <h1>about</h1> 
      <p>
      You can NavLink include the tag in the /public/index.html, and then use the script as you use it in normal JS code, following example for if you want to use jQuery:
      You can include the tag in the /public/index.html, and then use the script as you use it in normal JS code, following example for if you want to use jQuery:
      You can include the tag in the /public/index.html, and then use the script as you use it in normal JS code, following example for if you want to use jQuery:
      You can include the tag in the /public/index.html, and then use the script as you use it in normal JS code, following example for if you want to use jQuery:

      </p>
    </div>
  );
}

export default About;
